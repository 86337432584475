import React from "react"
import { Link } from "gatsby"

const PartnerLink = ({ partner }) => {
  return (
    <div>
      <Link to={partner.fields.slug}>{partner.frontmatter.name}</Link>
    </div>
  )
}

export default PartnerLink
