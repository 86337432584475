import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Content, { HTMLContent } from "../components/Content"
import PersonLink from "../components/PersonLink"
import PartnerLink from "../components/PartnerLink"

export const ProjectTemplate = ({ content, contentComponent }) => {
  const ProjectContent = contentComponent || Content

  return (
    <section className="section">
      {""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <ProjectContent className="markdown" content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

const ProjectPage = ({ data }) => {
  const { projectQuery, peopleQuery, partnersQuery } = data
  const project = projectQuery["frontmatter"]
  const people = peopleQuery["edges"]
  const partners = partnersQuery["edges"]

  const imageStyle = { borderRadius: "0px", maxWidth: 300 }

  return (
    <Layout>
      <h1 className="text-3xl my-6 font-bold">{project.name}</h1>

      <ProjectTemplate
        content={projectQuery.html}
        contentComponent={HTMLContent}
      />

      <Img style={imageStyle} fluid={project.image.childImageSharp.fluid} />
      <div className="font-bold mt-4">People</div>
      {people &&
        people.map((person, i) => <PersonLink key={i} person={person.node} />)}
      <div className="font-bold mt-4">Partners</div>
      {partners &&
        partners.map((partner, i) => (
          <PartnerLink key={i} partner={partner.node} />
        ))}
    </Layout>
  )
}

export default ProjectPage

// String! implies non-nullable
// We are guaranteed an id because once was assigned on page creation
export const pageQuery = graphql`
  query ProjectByID($id: String!, $people: [String], $partners: [String]) {
    projectQuery: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        people
        image {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    peopleQuery: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "person-page" }
          name: { in: $people }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            name
          }
          fields {
            slug
          }
        }
      }
    }
    partnersQuery: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "partner-page" }
          name: { in: $partners }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            name
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
